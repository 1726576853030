import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import { Layout, Parallax, DelayedLink } from '@common'
import {
	page__wrapper,
	page__quote,
	page__parallax,
	page__parallax__inner,
	page__buttons,
	page__button,
	page__button__alt,
	page__testimonial,
	page__list,
} from './styles/page.module.scss'

const Teaching = ({ pageContext, location }) => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				mediaItemBy(slug: "danial-ricaros-FCHlYvR5gJI-unsplash") {
					sourceUrl
					srcSet
					altText
				}
			}
		}
	`)

	return (
		<Layout location={location} page={pageContext.page}>
			<div className={page__wrapper}>
				<h1>Akademia Gladiatorów Javascriptu</h1>
				<div className={page__parallax}>
					<Parallax height={400} image={WP.mediaItemBy}>
						<div className={page__parallax__inner}>
							<h1>
								cykliczne zajęcia z mentorem{' '}
								<strong>Javascriptu i Reacta</strong>
							</h1>
							<h3>
								Umówmy się na regularne zajęcia online, abym
								pomógł Ci wejść skutecznie na rynek pracy
							</h3>
						</div>
					</Parallax>
				</div>
				<h3>W 2020 mam 11 podopiecznych Akademii</h3>
				<h1>
					w tym roku mogę przyjąć <strong>jeszcze 4 nowych</strong>{' '}
					uczniów
				</h1>
				<div className={page__buttons}>
					<div
						className={[page__button, page__button__alt].join(' ')}
					>
						<a
							href='https://academy.localhost-group.com'
							target='_blank'
						>
							Oficjalna strona Akademii
						</a>
						<span>&gt;</span>
					</div>
				</div>

				<h3>
					Jestem programistą i mentorem z pasji i moim celem jest
					nieść skuteczną pomoc i wsparcie najwyższej jakości tym,
					którzy chcą być atrakcyjniejsi na rynku pracy
				</h3>
				<ul>
					<li>
						<span>1</span>
						<h4>
							Akademia jest{' '}
							<strong>dla osób po kursach i bootcampach</strong>,
							które dalej zdobywają wiedzę i chcą skutecznie wejść
							na rynek i wyróżnić się swoimi umiejętnościami
						</h4>
					</li>
					<li>
						<span>2</span>
						<h4>
							<strong>Nie uczę nierobów i leni.</strong> Chcesz
							stać się dobrym programistą szybko? To dajemy z
							siebie wszystko
						</h4>
					</li>
					<li>
						<span>3</span>
						<h4>
							Uczę podchodząc do każdej osoby oraz jej poziomu
							indywidualnie - <strong>zajęcia są 1 na 1</strong>
						</h4>
					</li>
					<li>
						<span>4</span>
						<h4>
							Otrzymujesz <strong>Code Review</strong>, który
							otworzy Ci oczy na wiele spraw na kodowanie. Pokażę
							Ci gdzie coś jest nie tak, a potem jak powinno
							wyglądać rozwiązanie - abyś wiedział na następny raz
							jak to lepiej ugryźć
						</h4>
					</li>
				</ul>

				<h1>
					Pobyt w Akademii to inwestycja czasu, energii oraz
					pieniędzy, ale zwróci Ci się w 3 miesiące jak już zostaniesz
					Gladiatorem Javascriptu. Albo szybciej
				</h1>
				<h2>
					<strong>Członkowstwo Akademii</strong>{' '}
					<small>1000PLN / msc</small>
				</h2>
				<h4>
					Godzina korepetycji na wybrany przez Ciebie temat{' '}
					<small>120PLN / h</small>
				</h4>
				<br />
				<br />
				<br />
				<br />

				<h3>
					Możesz <strong>sprawdzić czy się nadajesz</strong> robiąć 5
					dowolnych zadań z JS i React. Link jest w menu.
				</h3>

				<h2>
					Rozwiążesz dowolne 8 moich zadań dobrze i dostaniesz{' '}
					<strong>200PLN regularnej zniżki</strong>
				</h2>
				<h1>Zainteresowany? Omówmy szczegóły podczas rozmowy</h1>
				<div className={page__buttons}>
					<div className={[page__button].join(' ')}>
						<span>&lt;</span>
						<OutboundLink
							target='_blank'
							href='https://www.facebook.com/localhost40310'
						>
							Napisz na fanpage
						</OutboundLink>
					</div>
					<div className={[page__button].join(' ')}>
						<span>&lt;</span>
						<OutboundLink href='mailto:contact@localhost-group.com'>
							Napisz maila
						</OutboundLink>
					</div>
					<div
						className={[page__button, page__button__alt].join(' ')}
					>
						<OutboundLink href='tel:+48535000959'>
							A najlepiej to zadzwoń (13:00-23:00)
						</OutboundLink>
						<span>&gt;</span>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Teaching
